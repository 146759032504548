import Tooltip from '@ingka/tooltip';

function TooltipWithCondition(
  { children, tooltipText }: { children: React.ReactNode, tooltipText: string | null },
) {
  return tooltipText ? (
    <Tooltip tooltipText={tooltipText} className="font-normal [&__.tooltip__body]:max-w-[17rem]">
      {children}
    </Tooltip>
  ) : children;
}
export default TooltipWithCondition;
