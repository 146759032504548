import { SidebarHeader } from '../SidebarHeader';
import {
  useGetOTCState,
} from '../../../store/features/OTCSlice/OTCSlice';

export function OTCBlindReturnArticleActionsHeader() {
  const article = useGetOTCState('articleInSidebar');

  return (
    <SidebarHeader title={article?.name || ''} />
  );
}
