import { SidebarHeader } from '../SidebarHeader';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';

export function OTCArticleActionsHeader() {
  const article = useGetOTCState('articleInSidebar');

  return (
    <SidebarHeader title={article?.name || ''} />
  );
}
