import { ModalBody } from '@ingka/modal';
import { ArticleViewer, ArticleViewerProps } from '@resolutions/article-viewer';
import { useTranslation } from 'react-i18next';
import { useGetOTCState } from '../../../store/features/OTCSlice/OTCSlice';
import { useMarketLocale } from '../../../hooks/useMarketLocale';

export function OTCArticleViewerBody() {
  const { t } = useTranslation();
  const article = useGetOTCState('articleInSidebar');
  const { countryCode, languageCode } = useMarketLocale();

  if (!article) return null;

  return (
    <ModalBody className="!pt-0">
      <ArticleViewer
        article={{
          description: article.description,
          imageAlt: article.productImage?.alt ?? '',
          imageUrl: article.productImage?.url.replace('s2.', 's4.') ?? '',
          itemNo: article.itemNo,
          itemType: article.itemType as ArticleViewerProps['article']['itemType'],
          name: article.name,
        }}
        copyButtonAriaLabel={t('otc-article-viewer-sidebar.copyArticleNumber')}
        countryCode={countryCode.toLocaleLowerCase()}
        languageCode={languageCode.toLocaleLowerCase()}
        quickLinksTitle={t('otc-article-viewer-sidebar.quickLinks')}
      />
    </ModalBody>
  );
}
