import { useTranslation } from 'react-i18next';
import { useGetPromptState } from '../../store/features/prompt/promptSlice';
import { ConfirmActionFooter } from './ConfirmActionFooter';
import { RetryActionFooter } from './RetryActionFooter';
import PickupFailedRadioGroup from './PickupFailedRadioGroup';
import ArticlesWithIncorrectUnitBody from './ArticlesWithIncorrectUnitBody';
import CloseOnlyFooter from './CloseOnlyFooter';
import MissingCustomerInfoBody from './MissingCustomerInfoBody';
import { PromptPageKey } from '../../store/features/prompt/utils';

type PromptConfigContentKeys = 'body' | 'footer' | 'title';

export const useGetPromptContent = () => {
  const page = useGetPromptState('page');
  const { t } = useTranslation();

  if (!page) {
    return {
      body: null,
      footer: null,
      title: null,
    };
  }
  type PromptConfig = {
    [Key in Exclude<PromptPageKey, null>]: () => {
      [ContentKey in PromptConfigContentKeys]: React.ReactNode;
    };
  };
  const promptConfig: PromptConfig = {
    'confirm-cancel': () => ({
      body: t('prompt-actions.confirm-cancel.body'),
      footer: <ConfirmActionFooter action="CANCEL" />,
      title: t('prompt-actions.confirm-cancel.title'),
    }),
    'retry-cancel': () => ({
      body: t('prompt-actions.retry-cancel.body'),
      footer: <RetryActionFooter />,
      title: t('prompt-actions.retry-cancel.title'),
    }),
    'retry-reschedule': () => ({
      body: t('prompt-actions.retry-reschedule.body'),
      footer: <RetryActionFooter />,
      title: t('prompt-actions.retry-reschedule.title'),
    }),
    'failed-pickup-reschedule': () => ({
      body: <PickupFailedRadioGroup action="RE_SCHEDULE_FAILED" />,
      footer: <ConfirmActionFooter action="RE_SCHEDULE_FAILED" />,
      title: t('prompt-actions.failed-pickup-reason.rescheduleTitle'),
    }),
    'failed-pickup-cancel': () => ({
      body: <PickupFailedRadioGroup action="CANCEL_FAILED" />,
      footer: <ConfirmActionFooter action="CANCEL_FAILED" />,
      title: t('prompt-actions.confirm-cancel.title'),
    }),
    'articles-with-incorrect-unit': () => ({
      body: <ArticlesWithIncorrectUnitBody />,
      footer: <CloseOnlyFooter />,
      title: t('prompt-actions.article-with-incorrect-unit.title'),
    }),
    'missing-customer-information': () => ({
      body: <MissingCustomerInfoBody />,
      footer: <CloseOnlyFooter />,
      title: t('prompt-actions.missing-customer-information.title'),
    }),
    'otc-remove-pop': () => ({
      body: t('prompt-actions.confirm-remove-proof-of-purchase.body'),
      footer: <ConfirmActionFooter action="REMOVE_POP" />,
      title: t('prompt-actions.confirm-remove-proof-of-purchase.title'),
    }),
    'otc-remove-selected-articles-pop': () => ({
      body: t('prompt-actions.confirm-remove-selected-articles.body'),
      footer: <ConfirmActionFooter action="REMOVE_SELECTED_ARTICLES_POP" />,
      title: t('prompt-actions.confirm-remove-selected-articles.title'),
    }),
    'otc-remove-article': () => ({
      body: t('prompt-actions.confirm-remove-article.body'),
      footer: <ConfirmActionFooter action="REMOVE_ARTICLE" />,
      title: t('prompt-actions.confirm-remove-article.title'),
    }),
    'otc-remove-blind-return-article': () => ({
      body: t('prompt-actions.confirm-remove-article.body'),
      footer: <ConfirmActionFooter action="REMOVE_BLIND_RETURN_ARTICLE" />,
      title: t('prompt-actions.confirm-remove-article.title'),
    }),
  };

  return promptConfig[page]();
};
