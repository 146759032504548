import { ModalHeader, ModalHeaderProps } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import {
  useCloseSidebar,
  useGetSidebarState,
} from '../../store/features/sidebar/sidebarSlice';

export type SidebarHeaderProps = Omit<ModalHeaderProps, 'ariaCloseTxt' | 'titleId'>;

export function SidebarHeader({ closeBtnClick, ...restModalHeaderProps }: SidebarHeaderProps) {
  const closeSideBar = useCloseSidebar();
  const sidebarPage = useGetSidebarState('page');
  const { t } = useTranslation();

  const onClose: ModalHeaderProps['closeBtnClick'] = (e) => {
    if (closeBtnClick) {
      closeBtnClick(e);
    } else {
      closeSideBar();
    }
  };

  return (
    <ModalHeader
      {...restModalHeaderProps}
      ariaCloseTxt={t('commonly-reused.close')}
      closeBtnClick={onClose}
      titleId={`${sidebarPage}-modalLabelId`}
    />
  );
}
