import { useGetAppState } from '../../../store/features/appStateSlice/appStateSlice';
import { useGetCustomerReturnQuery, useGetAvailableTimeWindowsQuery } from '../../../store/features/api/apiSlice';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { useQuestionnaireRequest } from '../initialFetchUtils';

export const useGetCustomerReturnResponse = () => {
  const returnId = useGetAppState('getReturnIdentifierParam');
  const { countryCode } = useMarketLocale();

  const getReturn = useGetCustomerReturnQuery(
    {
      returnId: returnId!,
      countryCode,
    },
    { skip: returnId === null },
  );
  return getReturn;
};

export const useGetAvailableTimeWindowsFetch = ({ forceSkip } = { forceSkip: false }) => {
  const fetchGetCustomerReturn = useGetCustomerReturnResponse();
  const actions = fetchGetCustomerReturn.data?.actions ?? [];
  const returnReference = fetchGetCustomerReturn.data?.returnReference ?? '';
  const hasRescheduleActions = (actions?.some((action) => (action === 'RE_SCHEDULE' || action === 'RE_SCHEDULE_FAILED')));
  const { countryCode } = useMarketLocale();

  return useGetAvailableTimeWindowsQuery({
    returnReference,
    countryCode,
  }, { skip: forceSkip || !returnReference || !hasRescheduleActions });
};

export const useViewInitialFetch = () => {
  const fetchGetCustomerReturn = useGetCustomerReturnResponse();
  const returnDoesntHaveQuestionnaire = !fetchGetCustomerReturn.data?.customer?.questionnaire;
  const fetchQuestionnaire = useQuestionnaireRequest(
    { skipCondition: returnDoesntHaveQuestionnaire },
  );

  useGetAvailableTimeWindowsFetch();

  return {
    fetchGetCustomerReturn,
    fetchQuestionnaire,
  };
};
