import InlineMessage from '@ingka/inline-message';
import Text from '@ingka/text';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import {
  DataCardConfigColumn,
} from 'shared-frontend';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';

export type RefundMethodData = {
  originalPaymentMethod: string
  missingPaymentMethod: string
  infoMessage: string
  causingBusinessUnit: string
  causingBusinessUnitMoreInfo: string
};

type ContentAndConfig = {
  config: DataCardConfigColumn<RefundMethodData>[],
  content: RefundMethodData,
};

export const useRefundMethodConfig = (): ContentAndConfig => {
  const { t } = useTranslation();
  const customerReturn = useGetAppState('customerReturn');

  const content: RefundMethodData = {
    originalPaymentMethod: t('refund-method-card.originalPaymentMethod'),
    missingPaymentMethod: t('refund-method-card.missingPaymentMethod'),
    infoMessage: t('refund-method-card.infoMessage'),
    causingBusinessUnit: t('refund-method-card.causingBusinessUnit'),
    causingBusinessUnitMoreInfo: t('refund-method-card.causingBusinessUnitMoreInfo'),
  };
  const config: DataCardConfigColumn<RefundMethodData>[] = [{
    rows: [{
      accessor: 'originalPaymentMethod',
    },
    { accessor: 'missingPaymentMethod' },
    {
      accessor: 'infoMessage',
      valueRender: (text) => (
        <InlineMessage
          subtle
          variant="informative"
          ssrIcon={informationCircle}
          body={text}
          className="my-4"
        />
      ),
    },
    {
      accessor: 'causingBusinessUnit',
      valueRender: () => {
        const { type, code } = customerReturn?.causingBusinessUnit ?? {};
        const renderValue = `${type} - ${code}`;
        return <Text className="font-bold">{t('refund-method-card.causingBusinessUnit', { unit: renderValue })}</Text>;
      },
    },
    { accessor: 'causingBusinessUnitMoreInfo' }],
  }, { // creates a 2 column data card
    rows: [],
  }];

  return { content, config };
};
