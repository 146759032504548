import {
  useGetAppState,
} from '../store/features/appStateSlice/appStateSlice';
import { useRescheduleReturnMutation } from '../store/features/api/apiSlice';
import { useMarketLocale } from './useMarketLocale';
import { useGetSelectedReturnMethodState } from '../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { RescheduleReturnPostMessage } from '../models/SamsPostMessage';
import { RescheduleReturnParams } from '../store/features/api/rescheduleReturn/rescheduleReturn';

export const useRescheduleReturn = () => {
  const { countryCode } = useMarketLocale();
  const returnId = useGetAppState('returnId')!;
  const [rescheduleReturnMutation, { isLoading }] = useRescheduleReturnMutation();
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const rescheduleTimeWindows = useGetAppState('rescheduleAvailableTimeWindows');
  const returnReference = useGetAppState('returnReference')!;
  const failedPickupReason = useGetAppState('failedPickupReason');
  const userId = useGetAppState('userId')!;

  if (!rescheduleTimeWindows || !selectedReturnMethod || !selectedReturnMethod.timeWindow) {
    return {
      rescheduleReturn: () => { },
      isRescheduleLoading: isLoading,
    };
  }

  const { returnOptionsId } = rescheduleTimeWindows;
  const {
    fromDateTime,
    resourcePoolId,
    timeWindowId,
    timeZone,
    toDateTime,
  } = selectedReturnMethod.timeWindow;

  const postBody: RescheduleReturnParams['postBody'] = {
    fromDateTime,
    resourcePoolId,
    returnOptionsId,
    timeWindowId,
    timezone: timeZone,
    toDateTime,
    updatedBy: userId,
    ...(failedPickupReason && { reasonCode: failedPickupReason }),
  };

  const postMessageApi = window.chrome?.webview?.postMessage;
  const encodeData = (data: RescheduleReturnPostMessage) => window.btoa(JSON.stringify(data));

  const rescheduleReturn = () => rescheduleReturnMutation({ countryCode, postBody, returnId })
    .unwrap()
    .then((data) => {
      if (!postMessageApi) return;

      const { expectedReturnDate, pickupDate } = data;

      postMessageApi(encodeData({
        action: 'RESCHEDULE',
        error: '',
        message: '',
        returnData: {
          expectedReturnDate,
          pickUpDate: pickupDate,
          returnId,
          returnReference,
        },
      }));
    })
    .catch((error: any) => {
      if (!postMessageApi) return;
      postMessageApi(encodeData({
        action: 'RESCHEDULE',
        error: 'RETURN_RESCHEDULE_FAILED',
        message: error.data?.message ?? 'Something went wrong, no additional information available',
        returnData: null,
      }));
    });

  return {
    rescheduleReturn,
    isRescheduleLoading: isLoading,
  };
};
