import { useMarketLocale } from '../useMarketLocale';
import { useRefundCalcResponse } from '../useRefundCalcResponse';
import { useQuestionnaireResponse } from '../../components/app/initialFetchUtils';
import { useSubmitReturnMutation } from '../../store/features/api/apiSlice';
import { useGetSelectedReturnMethodState, useGetSubmitReturnBody } from '../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { postSubmitResponse, ReturnInfo } from './postSubmitResponse';
import { useRefundCalculations } from '../../store/features/api/refundCalc/refundCalcHooks';
import { AppStateInterface } from '../../store/features/appStateSlice/utils';

export interface UseSubmitReturn {
  isMutationLoading: boolean;
  isSubmitButtonDisabled: boolean;
  returnReference: AppStateInterface['returnReference'];
  submitReturn: () => Promise<void>;
}

export function useSubmitReturn(): UseSubmitReturn {
  const { data: questionnaireResponse } = useQuestionnaireResponse();
  const { data: refund } = useRefundCalcResponse();
  const refundCalc = useRefundCalculations();
  const { countryCode } = useMarketLocale();
  const postBody = useGetSubmitReturnBody(refund, questionnaireResponse);
  const [submitReturnMutation, mutationState] = useSubmitReturnMutation();
  const selectedReturnMethod = useGetSelectedReturnMethodState('selectedReturnMethod');
  const returnReference = useGetAppState('returnReference');
  const isMutationLoading = mutationState.isLoading;
  const invalidPaidBy = postBody?.returnAgreement.returnSettlement.paidBy === 'INVALID';
  const isSubmitButtonDisabled = !!returnReference
    || isMutationLoading
    || !postBody
    || !refundCalc
    || invalidPaidBy;
  const handleSubmitReturn = async () => {
    if (!postBody || !selectedReturnMethod || !refundCalc || !refund || invalidPaidBy) return;
    const holdUntilPaid = selectedReturnMethod.marketConfig?.holdUntilPaid?.enabled;
    const returnInfo: ReturnInfo = {
      isPickUp: selectedReturnMethod.isPickUp,
      price: selectedReturnMethod.price,
      receivingBusinessUnit: selectedReturnMethod.receivingBusinessUnit!,
      timeWindow: selectedReturnMethod.timeWindow,
      refund: {
        amount: refundCalc.estimatedRefund,
        currencyCode: refundCalc.currencyCode,
      },
      ...(holdUntilPaid && {
        holdUntilPaid,
      }),
    };

    submitReturnMutation({ countryCode, postBody })
      .unwrap()
      .then((payload) => {
        postSubmitResponse({
          ...payload,
          returnInfo,
          refundSummary: refund.summary,
        });
      })
      .catch((error) => {
        postSubmitResponse({
          error: error.status,
          message: error?.data?.message,
          returnInfo,
        });
      });
  };

  return {
    isMutationLoading,
    isSubmitButtonDisabled,
    returnReference,
    submitReturn: handleSubmitReturn,
  };
}
