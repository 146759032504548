import { ModalBody } from '@ingka/modal';
import { ConditionOfGoods, ConditionOfGoodsProps } from '@resolutions/condition-of-goods';
import { useTranslation } from 'react-i18next';
import { useGetOTCState, useSetConditionOfGoods } from '../../../store/features/OTCSlice/OTCSlice';

export function OTCConditionOfGoodsBody() {
  const { t } = useTranslation();
  const translations = t('resolutions:condition-of-goods-picker', {
    returnObjects: true,
  }) as ConditionOfGoodsProps['translations'];
  const articleInSidebar = useGetOTCState('articleInSidebar');
  const setConditionOfGoods = useSetConditionOfGoods();

  if (!articleInSidebar) return null;

  const { canBeAssembled, conditionOfGoods, issue } = articleInSidebar;

  return (
    <ModalBody>
      <ConditionOfGoods
        canBeAssembled={canBeAssembled}
        isAssembled={conditionOfGoods?.isAssembled}
        isBulk={false} // TODO: fix in bulk story
        issueCategory={issue?.category}
        onSelection={setConditionOfGoods}
        packageCondition={conditionOfGoods?.packageCondition}
        translations={translations}
      />
    </ModalBody>
  );
}
