/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { useLamnaDispatch, useLamnaSelector } from '../../app/hooks';
import { addApiMatchers } from './apiMatchers';
import { ToastState, ToastType } from './utils';

export const toastInitialState: ToastState = {
  isVisible: false,
  message: '',
  type: null,
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState: toastInitialState,
  reducers: {
    showToast: (
      state: ToastState,
      action: PayloadAction<{ message: string; type: ToastType }>,
    ) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.isVisible = true;
    },
    hideToast: (state: ToastState) => {
      state.isVisible = false;
      state.message = '';
      state.type = null;
    },
  },
  extraReducers: (builder) => {
    addApiMatchers(builder);
  },
});

// convenience selectors and actions
export const useGetToast = () => useLamnaSelector((state) => state.toast);

export const useToast = () => {
  const dispatch = useLamnaDispatch();
  const showToast = (message: string, type: ToastType) => {
    dispatch(toastSlice.actions.showToast({ message, type }));
  };
  const hideToast = () => {
    dispatch(toastSlice.actions.hideToast());
  };
  return { showToast, hideToast };
};
