import { ReturnMethodPage } from './ReturnMethodPage/ReturnMethodPage';
import { ReturnMethodPageFooter } from './ReturnMethodPage/ReturnMethodPageFooter';
import { ReturnMethodPageHeader } from './ReturnMethodPage/ReturnMethodPageHeader';
import { QuestionnaireFooter } from './QuestionnairePage/QuestionnaireFooter';
import { QuestionnaireHeader } from './QuestionnairePage/QuestionnaireHeader';
import { useGetSidebarState } from '../../store/features/sidebar/sidebarSlice';
import { SidebarPageKey } from '../../store/features/standAloneActions/extraReducersActions';
import { QuestionnairePage } from './QuestionnairePage/QuestionnairePage';
import { TimeWindowPage } from './TimeWindowPage/TimeWindowPage';
import { TimeWindowPageFooter } from './TimeWindowPage/TimeWindowPageFooter';
import { TimeWindowPageHeader } from './TimeWindowPage/TimeWindowPageHeader';
import { MoreMenuBody } from './MoreMenuPage/MoreMenuBody';
import { MoreMenuHeader } from './MoreMenuPage/MoreMenuHeader';
import { HistoryLogBody } from './HistoryLogPage/HistoryLogBody';
import { HistoryLogHeader } from './HistoryLogPage/HistoryLogHeader';
import { SidebarCloseFooter } from './SidebarCloseFooter';
import { OTCPoPActionsBody } from './OTCPoPActions/OTCPoPActionsBody';
import { OTCPoPActionsHeader } from './OTCPoPActions/OTCPoPActionsHeader';
import { OTCArticleActionsBody } from './OTCArticleActions/OTCArticleActionsBody';
import { OTCArticleActionsHeader } from './OTCArticleActions/OTCArticleActionsHeader';
import { OTCSearchBody } from './OTCSearchPage/OTCSearchBody';
import { OTCSearchHeader } from './OTCSearchPage/OTCSearchHeader';
import { OTCArticleViewerBody } from './OTCArticleViewer/OTCArticleViewerBody';
import { OTCArticleViewerHeader } from './OTCArticleViewer/OTCArticleViewerHeader';
import { OTCBlindReturnArticleActionsBody } from './OTCBlindReturnArticleActions/OTCBlindReturnArticleActionsBody';
import { OTCBlindReturnArticleActionsHeader } from './OTCBlindReturnArticleActions/OTCBlindReturnArticleActionsHeader';
import { OTCConditionOfGoodsBody } from './OTCConditionOfGoods/OTCConditionOfGoodsBody';
import { OTCConditionOfGoodsHeader } from './OTCConditionOfGoods/OTCConditionOfGoodsHeader';
import { OTCIssuePickerBody } from './OTCIssuePickerPage/OTCIssuePickerBody';
import { OTCIssuePickerFooter } from './OTCIssuePickerPage/OTCIssuePickerFooter';
import { OTCIssuePickerHeader } from './OTCIssuePickerPage/OTCIssuePickerHeader';

type PageMap = {
  [key in SidebarPageKey]: {
    body: React.ComponentType;
    header: React.ComponentType;
    footer: React.ComponentType;
  }
};
const components: PageMap = {
  'return-method': {
    body: ReturnMethodPage,
    header: ReturnMethodPageHeader,
    footer: ReturnMethodPageFooter,
  },
  questionnaire: {
    body: QuestionnairePage,
    header: QuestionnaireHeader,
    footer: QuestionnaireFooter,
  },
  'time-window-calendar': {
    body: TimeWindowPage,
    header: TimeWindowPageHeader,
    footer: TimeWindowPageFooter,
  },
  'more-menu': {
    body: MoreMenuBody,
    footer: SidebarCloseFooter,
    header: MoreMenuHeader,
  },
  'history-log': {
    body: HistoryLogBody,
    footer: SidebarCloseFooter,
    header: HistoryLogHeader,
  },
  'otc-search': {
    body: OTCSearchBody,
    footer: SidebarCloseFooter,
    header: OTCSearchHeader,
  },
  'otc-article-viewer': {
    body: OTCArticleViewerBody,
    footer: SidebarCloseFooter,
    header: OTCArticleViewerHeader,
  },
  'otc-pop-actions': {
    body: OTCPoPActionsBody,
    footer: SidebarCloseFooter,
    header: OTCPoPActionsHeader,
  },
  'otc-article-actions': {
    body: OTCArticleActionsBody,
    footer: SidebarCloseFooter,
    header: OTCArticleActionsHeader,
  },
  'otc-blind-return-article-actions': {
    body: OTCBlindReturnArticleActionsBody,
    footer: SidebarCloseFooter,
    header: OTCBlindReturnArticleActionsHeader,
  },
  'otc-condition-of-goods': {
    body: OTCConditionOfGoodsBody,
    footer: SidebarCloseFooter,
    header: OTCConditionOfGoodsHeader,
  },
  'otc-issue-picker': {
    body: OTCIssuePickerBody,
    footer: OTCIssuePickerFooter,
    header: OTCIssuePickerHeader,
  },
};

function SheetBodyRouter() {
  const page = useGetSidebarState('page');
  const Component = components[page]?.body;
  return Component ? <Component /> : null;
}

function SheetHeaderRouter() {
  const page = useGetSidebarState('page');
  const Component = components[page]?.header;

  // This is needed because Skapa validates the displayname to be like below
  if (Component) {
    Component.displayName = 'ModalHeader';
  }
  return Component ? <Component /> : null;
}

function SheetFooterRouter() {
  const page = useGetSidebarState('page');
  const Component = components[page]?.footer;

  // This is needed because Skapa validates the displayname to be like below
  if (Component) {
    Component.displayName = 'ModalFooter';
  }
  return Component ? <Component /> : null;
}

export default function SidebarRouter({ children }: { children: React.ReactElement }) {
  return children;
}

SidebarRouter.Footer = SheetFooterRouter;
SidebarRouter.Header = SheetHeaderRouter;
SidebarRouter.Body = SheetBodyRouter;
