import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { getEnabledFeatures, getQueryParams } from 'shared-frontend';
import config, { Config } from './config';
import { apiSlice } from './store/features/api/apiSlice';
import initTranslations, { getCountryAndLanguageFromUrl } from './i18n';
import { createGetReturnFunc } from './utils/modeSetup/getReturn';
import { createInitReturnFunc, getSanitizedSac } from './utils/modeSetup/initReturn';
import { toCustomerReturn } from './utils/sacUtils';
import { store } from './store/app/store';
import { appStateSlice } from './store/features/appStateSlice/appStateSlice';
import { setInitialCreateMode } from './store/features/standAloneActions/extraReducersActions';
import { urlMap, getReturnIdentifierFromUrl } from './hooks/useAppRoutes';
import { STATUS, validateJWT } from './utils/tokenUtils';
import { Sac } from './models';
import { base64ToUtf8String, stringToBase64Utf8 } from './utils/base64utils';
import { MarketConfig } from './store/features/api/marketConfig/marketConfig';
import getAuth, { GetUserResponse } from './auth';

declare global {
  interface Window {
    chrome?: {
      webview?: {
        postMessage?: (data: string) => void
      }
    },
    config: Config,
    cwuiSentryReleaseVersion: string;
    getReturn: ReturnType<typeof createGetReturnFunc>,
    initReturn: ReturnType<typeof createInitReturnFunc>,
    toBase64: typeof stringToBase64Utf8,
    getSanitizedSac: () => Omit<Sac, 'auth'>,
    salja: {
      authentication: {
        getUser: () => Promise<GetUserResponse>
      }
    },
  }
}

export const fetchMarketConfig = async () => {
  const [countryCode] = getCountryAndLanguageFromUrl();
  const response = store.dispatch(
    apiSlice.endpoints.marketConfig.initiate({
      countryCode: countryCode.toLowerCase() as Lowercase<string>,
    }),
  );

  try {
    const marketConfig = await response.unwrap();
    return marketConfig;
  } catch (error) {
    return {
      error,
    };
  }
};

export const enableDevSettings = () => import.meta.env.DEV
  && Object.prototype.hasOwnProperty.call(getQueryParams(), 'devOverlay');

const initReturnFromQueryParams = async (cfg: Config, features: MarketConfig['features']) => {
  const { token, sacIndex, userId } = getQueryParams();
  const path = window.location.pathname;

  if (!token) return;

  const validateJwtResult = validateJWT(token, cfg);
  if (validateJwtResult === STATUS.INVALID) return;

  if (path.includes(urlMap.VIEW)) {
    const getReturnIdentifierParam = getReturnIdentifierFromUrl(path);
    if (!userId || !getReturnIdentifierParam) { return; }

    store.dispatch(appStateSlice.actions.setInitialViewMode({
      accessToken: token,
      getReturnIdentifierParam,
      isAuthenticated: true,
      userId,
    }));
    return;
  }

  if (path.includes(urlMap.CREATE)) {
    const { getSacFromQueryParam } = await import('./utils/sacDevUtils');
    const sac = getSacFromQueryParam(sacIndex);
    window.getSanitizedSac = () => getSanitizedSac(sac);
    const enabledFeatures = getEnabledFeatures(features);
    const customerReturn = toCustomerReturn(sac, enabledFeatures);
    store.dispatch(setInitialCreateMode({
      accessToken: token,
      customerReturn,
      isAuthenticated: true,
      userId: sac.auth.userId,
    }));
  }
};

export const prepareAppSetup = async () => {
  if (import.meta.env.PROD) {
    Sentry.init({
      dsn: 'https://ee2760c133a73a5d677ea6d8209b89df@o478484.ingest.sentry.io/4505923850928128',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      maxValueLength: 100000,
      release: window.cwuiSentryReleaseVersion,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
      tracesSampleRate: 1.0,
    });
  }

  const cfg = await config();
  window.config = cfg;

  const isEmbedded = window.location.pathname.includes(urlMap.EMBEDDED);

  if (!isEmbedded) {
    const authentication = await getAuth();
    const {
      accessToken, expiresOn, extExpiresOn, status,
    } = authentication;
    const expDateString = expiresOn?.toUTCString() ?? null;
    const extensionDateString = extExpiresOn?.toUTCString() ?? null;

    store.dispatch(appStateSlice.actions.setCoworkerAuth({
      accessToken,
      expiresOn: expDateString,
      extExpiresOn: extensionDateString,
      status,
    }));
  }

  const marketConfig = await fetchMarketConfig();
  window.initReturn = createInitReturnFunc(cfg, marketConfig);
  window.getReturn = createGetReturnFunc(cfg, marketConfig);
  window.toBase64 = stringToBase64Utf8;

  if (enableDevSettings()) {
    const { worker } = await import('./mocks/browser');
    worker().start(
      {
        onUnhandledRequest: 'bypass',
      },
    );
  }

  initReturnFromQueryParams(cfg, (marketConfig as MarketConfig)?.features);
  initTranslations();
  if (!window.chrome?.webview?.postMessage) {
    window.chrome = {
      ...window.chrome,
      webview: {
        postMessage: (data: string) => {
          /* eslint-disable no-console */
          console.warn('chrome.webview.postMessage is not defined. If you are running in browser this is expected, api was called with following argument');
          console.log('encoded data: ', data);
          console.log('decoded data: ', JSON.parse(base64ToUtf8String(data)));
          /* eslint-enable no-console */
        },
      },
    };
  }

  const container = document.getElementById('root');
  const root = createRoot(container!);
  return root;
};
