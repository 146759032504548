import { IsomReturnOption, Price, SelectedReturnMethod } from 'shared-frontend';
import {
  CustomerReturnItem,
} from '../../../../models';
import { RefundCalcResponse } from '../../../../models/refundCalcResponse';
import type { AppEndPointBuilder } from '../apiSlice';
import {
  mapCollectionServicePriceForApi,
  mapProductsForApi,
  RefundCalcBodyCollectionService,
  RefundCalcBodyItems,
} from './refundCalcUtils';
import { PaidBy } from '../../../../models/CustomerReturn';

type RefundCalcApiConfig = {
  items: CustomerReturnItem[],
  servicePrice: Price,
  countryCode: string,
  selectedReturnOptionId: SelectedReturnMethod<IsomReturnOption>['id'],
  paidBy: PaidBy
};

type RefundCalcPayloadContract = {
  items: RefundCalcBodyItems[],
  collectionService: RefundCalcBodyCollectionService,
  returnOption: SelectedReturnMethod<IsomReturnOption>['id'],
  paidBy: PaidBy
};

export const fetchReturnOptionsApi = ({
  items,
  servicePrice,
  countryCode,
  selectedReturnOptionId,
  paidBy,
}: RefundCalcApiConfig) => {
  const body: RefundCalcPayloadContract = {
    items: mapProductsForApi(items),
    collectionService: mapCollectionServicePriceForApi(servicePrice),
    returnOption: selectedReturnOptionId,
    paidBy,
  };

  return ({ url: `/v1/${countryCode}/price-calculation/refund-summaries`, method: 'POST', body });
};

export const refundCalcBuilder = (builder: AppEndPointBuilder) => builder.query<
RefundCalcResponse,
RefundCalcApiConfig>({
  query: (config: RefundCalcApiConfig) => {
    const { url, method, body } = fetchReturnOptionsApi(config);
    return {
      url,
      method,
      body,
    };
  },
});
