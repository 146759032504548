import ListView, { ListViewItem } from '@ingka/list-view';
import { ModalBody } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import { useGoToPromptPage } from '../../../store/features/prompt/promptSlice';
import { useGetOTCState, useGetItemsForPop } from '../../../store/features/OTCSlice/OTCSlice';
import { useGoToSidebarPage } from '../../../store/features/sidebar/sidebarSlice';

export function OTCPoPActionsBody() {
  const goToPromptRemovePop = useGoToPromptPage('otc-remove-pop');
  const goToIssuePickerSidebar = useGoToSidebarPage('otc-issue-picker');
  const goToPromptRemoveSelectedArticles = useGoToPromptPage('otc-remove-selected-articles-pop');
  const currentPopId = useGetOTCState('actionsSidebarPopId');
  const items = useGetItemsForPop(currentPopId);
  const { t } = useTranslation();

  const isRemoveSelectedArticlesDisabled = !items?.length;
  const hasSelectedArticles = items?.some((item) => item.checked);

  return (
    <ModalBody>
      <ListView
        id="popActions"
        showDivider
      >
        <ListViewItem
          data-testid="remove-pop"
          className="hover:bg-neutral-grey-100 [&_svg]:hidden"
          control="navigational"
          emphasised
          inset
          key="REMOVE_POP"
          onClick={goToPromptRemovePop}
          title={t('prompt-actions.confirm-remove-proof-of-purchase.title')}
        />
      </ListView>
      <ListView
        id="selectedArticlesActions"
        showDivider={false}
      >
        <ListViewItem
          data-testid="remove-selected-articles"
          className="hover:bg-neutral-grey-100 [&_svg]:hidden"
          control="navigational"
          emphasised
          inset
          key="REMOVE_SELECTED_ARTICLES"
          onClick={goToPromptRemoveSelectedArticles}
          title={t('prompt-actions.confirm-remove-selected-articles.title')}
          disabled={isRemoveSelectedArticlesDisabled}
        />
        <ListViewItem
          data-testid="bulk-select-issue"
          className="hover:bg-neutral-grey-100 [&_svg]:hidden"
          control="navigational"
          emphasised
          inset
          key="BULK_SELECT_ISSUE"
          onClick={goToIssuePickerSidebar}
          title={t('otc-table-actions.bulkSelectIssue')}
          disabled={!hasSelectedArticles}
        />
      </ListView>
    </ModalBody>
  );
}
