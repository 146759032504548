import { useTranslation } from 'react-i18next';
import { StatusIcon } from 'shared-frontend';
import { Status } from './itemConditionOfGoods.types';
import { CdcFlow, StoFlow } from '../../../models/CustomerReturn';
import TooltipWithCondition from '../../Tooltips/TooltipWithCondition';

type StockCorrectionsPresentation = { quantity: number, label: string };

export default function StockCorrections({ receivingInfo }: {
  receivingInfo: CdcFlow | StoFlow,
}) {
  const { t } = useTranslation();

  const title: Status = {
    icon: 'info',
    label: t('articles-reason-card.receivingInfo.stockCorrections'),
    classes: '[&_span]:font-bold',
    show: true,
    testId: 'stockCorrections',
  };

  const totalQuantity = receivingInfo.okQuantity + receivingInfo.damagedQuantity;
  const sellableQuantity = totalQuantity - receivingInfo.unSellableQuantity;

  const stockCorrections: (StockCorrectionsPresentation & { tooltipText?: string })[] = [];
  const { unSellableQuantity } = receivingInfo;

  if ('notInSellableRangeQuantity' in receivingInfo && 'salesStopQuantity' in receivingInfo) {
    // STO Flow
    stockCorrections.push({
      quantity: sellableQuantity,
      label: t('articles-reason-card.receivingInfo.sellableQuantitySTO'),
      tooltipText: 'articles-reason-card.receivingInfo.sellableQuantitySTO',
    });

    const { salesStopQuantity, notInSellableRangeQuantity } = receivingInfo;

    const unSellableDamagedQuantity = unSellableQuantity
      - salesStopQuantity
      - notInSellableRangeQuantity;

    if (receivingInfo.notInSellableRangeQuantity > 0) {
      stockCorrections.push({
        quantity: receivingInfo.notInSellableRangeQuantity,
        label: t('articles-reason-card.receivingInfo.notInSellableRangeQuantitySTO'),
      });
    }

    if (salesStopQuantity > 0) {
      stockCorrections.push({
        quantity: salesStopQuantity,
        label: t('articles-reason-card.receivingInfo.salesStopQuantitySTO'),
      });
    }

    if (unSellableDamagedQuantity > 0) {
      stockCorrections.push({
        quantity: unSellableDamagedQuantity,
        label: t('articles-reason-card.receivingInfo.unSellableDamagedQuantitySTO'),
      });
    }
  } else {
    // CDC Flow
    stockCorrections.push({
      quantity: sellableQuantity,
      label: t('articles-reason-card.receivingInfo.sellableQuantityCDC'),
      tooltipText: 'articles-reason-card.receivingInfo.sellableQuantityCDC',
    });

    if (unSellableQuantity > 0) {
      stockCorrections.push({
        quantity: unSellableQuantity,
        label: t('articles-reason-card.receivingInfo.unSellableQuantityCDC'),
      });
    }
  }
  return (
    <div className="flex flex-col">
      <TooltipWithCondition tooltipText={t('articles-reason-card.receivingInfo.sellableAndUnsellableTooltip')}>
        <StatusIcon
          className={`pt-2 ${title.classes}`}
          icon={title.icon}
          label={title.label}
          testId={title.testId}
        />
      </TooltipWithCondition>
      <p className="ml-3 translate-x-6">
        {stockCorrections.map(({ quantity, label }) => (
          <span
            key={`${quantity}-${label}`}
            className="block"
          >
            {quantity}
            {' '}
            {label}
          </span>
        ))}
      </p>
    </div>

  );
}
