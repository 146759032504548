import { Config } from '../../config';
import { reportErrorToSentry } from '../reportErrorToSentry';
import { handleMarketConfigError, setupErrorMessage, MarketConfigError } from './handleSetupError';
import { store } from '../../store/app/store';
import { appStateSlice } from '../../store/features/appStateSlice/appStateSlice';
import { getReturnIdentifierFromUrl } from '../../hooks/useAppRoutes';
import { STATUS, validateJWT } from '../tokenUtils';
import { base64ToUtf8String } from '../base64utils';
import { MarketConfig } from '../../store/features/api/marketConfig/marketConfig';

export function createGetReturnFunc(cfg: Config, marketConfig: MarketConfig | any) {
  return function getReturn(encodedAuth: string = '') {
    try {
      const decodedAuth = JSON.parse(base64ToUtf8String(encodedAuth));

      const validateJwtResult = validateJWT(decodedAuth?.auth?.accessToken, cfg);
      if (validateJwtResult === STATUS.INVALID) {
        return validateJwtResult;
      }

      if (!decodedAuth?.auth?.userId) {
        return STATUS.MISSING_USER_ID;
      }

      const path = window.location.pathname;
      const getReturnIdentifierParam = getReturnIdentifierFromUrl(path);
      if (!getReturnIdentifierParam) {
        return STATUS.MISSING_RETURN_ID;
      }

      handleMarketConfigError({ marketConfig, returnIdentifier: getReturnIdentifierParam });

      store.dispatch(appStateSlice.actions.setInitialViewMode({
        accessToken: decodedAuth.auth.accessToken,
        getReturnIdentifierParam,
        isAuthenticated: true,
        userId: decodedAuth.auth.userId,
      }));

      return STATUS.VALID;
    } catch (e) {
      const enrichedError = (e instanceof MarketConfigError) ? e : setupErrorMessage(e);
      reportErrorToSentry(enrichedError);
      return enrichedError;
    }
  };
}
