export interface ArticleInputProps {
  ariaLabel: string;
  className?: string;
  value: string;
}

function ArticleInput({ ariaLabel, className, value }: ArticleInputProps) {
  return (
    <input
      aria-label={ariaLabel}
      className={`px-2 grow font-normal text-base h-9 leading-6 rounded-sm border-[1px] border-lightest bg-white
        w-full text-ellipsis text-lightest ${className} text-m`}
      title={value}
      value={value}
      disabled
    />
  );
}

export default ArticleInput;
