import { nanoid } from '@reduxjs/toolkit';
import { AvailableTimeWindows } from 'shared-frontend/src/models/return-method';
import {
  ReturnTaxDetails, ReturnPrice, SacItemPrice, SacTaxDetails,
  CustomerReturn,
  StatusViewModel,
} from '../../../models';
import {
  CustomerReturnView,
  GetCustomerReturnResponse,
} from '../../../models/GetCustomerReturnResponse';
import { ArticlesWithIncorrectUnit } from '../standAloneActions/extraReducersActions';
import { RefreshAuth } from '../standAloneActions/saljaUser';
import { PreferredCommunicationMethod } from '../../../models/CustomerReturnRequest';

export type InitialViewModePayload = {
  accessToken: string;
  getReturnIdentifierParam: string | null;
  isAuthenticated: boolean;
  userId: string | null;
};

export type InitialBaseModePayload = {
  accessToken: string;
  isAuthenticated: boolean;
};

export interface AppStateInterface {
  accessToken: string | null;
  coworkerAuth: Omit<RefreshAuth, 'accessToken'>;
  articlesWithIncorrectUnit: ArticlesWithIncorrectUnit;
  customerReturn: CustomerReturn | CustomerReturnView | null;
  failedPickupReason: 'FAILED_TSP' | 'FAILED_CUSTOMER' | null;
  features: string[]
  getReturnIdentifierParam: string | null;
  isAuthenticated: boolean;
  preferredCommunicationMethod: PreferredCommunicationMethod;
  returnId: string | null;
  returnReference: string | null;
  rescheduleAvailableTimeWindows: {
    availableTimeWindows: AvailableTimeWindows,
    proposedTimeWindowId: string,
    returnOptionsId: string
  } | null
  selfServiceViewURL: string | null;
  showSaveCustomerReturnButton: boolean;
  showStockCorrection: boolean;
  statusViewModel: StatusViewModel | null;
  userId: string | null;
}
export function isViewReturn(
  customerReturn: CustomerReturn | CustomerReturnView | null,
): customerReturn is CustomerReturnView {
  return Boolean((customerReturn as any)?.returnAgreement);
}

export function mapTaxDetails(details: SacTaxDetails[]): ReturnTaxDetails[] {
  return details.map((detail) => {
    const { rate, type, code } = detail;
    return {
      taxType: type,
      taxCode: code,
      taxPercentage: rate,
    };
  });
}
export function mapPriceInfo(price: SacItemPrice): ReturnPrice {
  const {
    amount, totalAmount, currency, includingTax, tax,
  } = price;
  return {
    totalPrice: {
      currencyCode: currency,
      priceInclTax: includingTax ? totalAmount : null,
      priceExclTax: includingTax ? null : totalAmount,
    },
    unitPrice: {
      currencyCode: currency,
      priceInclTax: includingTax ? amount : null,
      priceExclTax: includingTax ? null : amount,
      taxDetails: mapTaxDetails(tax),
    },
  };
}

export function mapSacPriceToReturnPrice({
  price, originalPrice,
}: { price: SacItemPrice, originalPrice: SacItemPrice, }) {
  return {
    price: mapPriceInfo(price),
    originalPrice: mapPriceInfo(originalPrice),
  };
}

export function mapGetReturnResponseItemsToCustomerReturn(
  responseItems: GetCustomerReturnResponse['itemViewModel'],
) {
  return responseItems.map((item) => ({
    ...item,
    ...(!item.id && { id: nanoid() }),
  }));
}

export function mapGetReturnResponseToCustomerReturn(
  returnResponse: GetCustomerReturnResponse,
): CustomerReturnView {
  const {
    preferredCommunicationMethod,
    questionnaire,
    ...filteredReturnCustomer
  } = returnResponse.customer;

  return {
    sacId: returnResponse.sourceReference.value,
    causingBusinessUnit: returnResponse.causingBusinessUnit,
    numberOfLabels: Number(returnResponse.returnAgreement.returnSettlement.numberOfLabels),
    paidBy: returnResponse.returnAgreement.returnSettlement.paidBy,
    isCreditPayment: returnResponse.returnAgreement.returnSettlement.isCreditPayment,
    items: mapGetReturnResponseItemsToCustomerReturn(
      returnResponse.itemViewModel,
    ),
    customer: {
      ...filteredReturnCustomer,
      isBusinessCustomer: Boolean(returnResponse.customer.isBusinessCustomer),
      preferredLanguage: returnResponse.customer.preferredLanguage || '',
    },
    returnAgreement: {
      ...returnResponse.returnAgreement,
    },
    actions: returnResponse.actions,
  };
}
