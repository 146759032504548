import { RefundCalcResponse } from '../../../../models/refundCalcResponse';
import { useRefundCalcResponse } from '../../../../hooks/useRefundCalcResponse';
import { useMarketConfigFetch } from '../../../../useMarketConfigFetch';
import { useGetAppState } from '../../appStateSlice/appStateSlice';

type PriceKey = keyof Pick<RefundCalcResponse['summary']['total'], 'amountExclTax' | 'amountInclTax'>;

export const useRefundCalculations = () => {
  const { data: refundResponse } = useRefundCalcResponse();
  const { data: marketConfig } = useMarketConfigFetch();
  const customerReturn = useGetAppState('customerReturn');
  if (!customerReturn) return null;

  const refund = ('returnAgreement' in customerReturn) ? customerReturn.returnAgreement.returnRefundAmount.refundSummary : refundResponse;

  if (!refund) return null;

  const purchaseInformation = marketConfig?.purchaseInformation;
  const priceKey: PriceKey = purchaseInformation?.itemPriceExcludesTax
    ? 'amountExclTax'
    : 'amountInclTax';

  return {
    collectionServiceCost: refund.summary.collectionService[priceKey],
    subTotal: refund.summary.subTotal[priceKey],
    currencyCode: refund.summary.total.currencyCode,
    estimatedRefund: refund.summary.total[priceKey],
    hasExchangeResolution: 'exchangeResolution' in customerReturn,
  };
};
