import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../features/api/apiSlice';
import { appStateSlice } from '../features/appStateSlice/appStateSlice';
import { OTCSlice } from '../features/OTCSlice/OTCSlice';
import { selectedReturnMethodSlice } from '../features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { sidebarSlice } from '../features/sidebar/sidebarSlice';
import { toastSlice } from '../features/toastSlice/toastSlice';
import { promptSlice } from '../features/prompt/promptSlice';
import sentryStoreEnhancer from './sentryStoreEnhancer';

const rootReducer = combineReducers({
  appState: appStateSlice.reducer,
  otc: OTCSlice.reducer,
  prompt: promptSlice.reducer,
  selectedReturnMethod: selectedReturnMethodSlice.reducer,
  sidebar: sidebarSlice.reducer,
  toast: toastSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    devTools: (import.meta.env.DEV),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers({
      autoBatch: { type: 'tick' },
    }).concat(sentryStoreEnhancer),
    preloadedState,
    reducer: rootReducer,
  });
}
export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;
