import { ModalFooter } from '@ingka/modal';
import { useTranslation } from 'react-i18next';
import Button from '@ingka/button';
import { useCloseSidebar } from '../../../store/features/sidebar/sidebarSlice';

function Wrapper({ children }: { children: React.ReactNode }) {
  return <ModalFooter className="modal-footer--dual-action" renderBorder>{children}</ModalFooter>;
}

export function OTCIssuePickerFooter() {
  const { t } = useTranslation();
  const close = useCloseSidebar();

  return (
    <Wrapper>
      <Button text={t('commonly-reused.cancel')} onClick={close} />
      <Button
        type="primary"
        disabled={false}
        text={t('commonly-reused.continue')}
        onClick={close}
      />
    </Wrapper>
  );
}
