import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import { ToastState } from './utils';

export const addApiMatchers = (builder: ActionReducerMapBuilder<ToastState>) => {
  builder
    .addMatcher(apiSlice.endpoints.submitReturn.matchFulfilled, (state) => {
      state.message = 'control-bar.toast.submitReturn.successTitle';
      state.type = 'success';
      state.isVisible = true;
    })
    .addMatcher(apiSlice.endpoints.submitReturn.matchRejected, (state) => {
      state.message = 'control-bar.toast.submitReturn.errorTitle';
      state.type = 'error';
      state.isVisible = true;
    })
    .addMatcher(apiSlice.endpoints.rescheduleReturn.matchFulfilled, (state) => {
      state.message = 'control-bar.toast.rescheduleReturn.successTitle';
      state.type = 'success';
      state.isVisible = true;
    })
    .addMatcher(apiSlice.endpoints.cancelReturn.matchFulfilled, (state) => {
      state.message = 'control-bar.toast.cancelReturn.successTitle';
      state.type = 'success';
      state.isVisible = true;
    })
    .addMatcher(apiSlice.endpoints.getReturnAuthorizationProducts.matchRejected, (state) => {
      state.message = 'control-bar.toast.getReturnAuthorizationProducts.errorTitle';
      state.type = 'error';
      state.isVisible = true;
    })
    .addMatcher(apiSlice.endpoints.getProductInfo.matchRejected, (state) => {
      state.message = 'control-bar.toast.getProductInfo.errorTitle';
      state.type = 'error';
      state.isVisible = true;
    });
};
