import type { PayloadAction } from '@reduxjs/toolkit';
import { ItemAdded, PickedItem } from '@resolutions/item-picker-contract';
import { IssueCategory, SelectionState } from '@resolutions/condition-of-goods';
import { PopPlugin } from '../../../hooks/useResolutionsScanner/validators/proofOfPurchase';
import { GetProductsResponse, Product } from '../api/returnAuthorization/products';
import { ProductInfo } from '../api/productInfo/getProductInfo';

export type BlindReturnItemFetching = {
  isFetching: boolean;
  itemLineId: string;
  itemNo: string;
  quantity: number;
};
export type BlindReturn = {
  checked: boolean;
  items: (BlindReturnItemFetching | PopItem)[];
};
export type ResolutionType = 'RETURN_INSTORE';
export type IssueType = {
  actor: string;
  category: IssueCategory,
  id: string;
  mainReasonCode: string;
  subReasonCode: string;
  title: string;
};
export type ItemReturnInfo = {
  canBeAssembled: boolean;
  checked: boolean;
  issue: IssueType | null;
  conditionOfGoods: SelectionState | null;
  popId: string | null;
  resolution: ResolutionType | null;
};
export type PopItem = PickedItem & ItemReturnInfo;

export const isPoPItem = (
  item: BlindReturnItemFetching | PopItem,
): item is PopItem => !('isFetching' in item);

export type StatePop = {
  checked: boolean;
  fetchedResponse: GetProductsResponse | null;
  id: string;
  isFetching: boolean;
  items: PopItem[];
  type: PopPlugin;
};

export type OTCState = {
  actionsSidebarPopId: string | null;
  articleInSidebar: PopItem | null;
  blindReturn: BlindReturn;
  pops: StatePop[];
  pipState: {
    isOpen: boolean,
    purchaseId: string | null,
  }
};

export const updateItemsForPop = (state: OTCState, action: PayloadAction<ItemAdded>) => {
  const { item, purchaseId } = action.payload;
  state.pops = state.pops.map((pop) => {
    let itemInExistingPopItems = false;
    if (pop.id !== purchaseId) return pop;

    let updatedPop = {
      ...pop,
      items: pop.items.map((popItem) => {
        if (popItem.itemNo !== item.itemNo || popItem.itemLineId !== item.itemLineId) {
          return popItem;
        }

        itemInExistingPopItems = true;

        const quantity = popItem.quantity + item.quantity;
        const unitPaidPrice = popItem.price.unit.paidPrice;
        const unitPriceExclTax = unitPaidPrice.priceExclTax ?? null;
        const unitPriceInclTax = unitPaidPrice.priceInclTax ?? null;
        return {
          ...popItem,
          deliveryReference: item.deliveryReference,
          dimensions: item.dimensions,
          price: {
            ...popItem.price,
            total: {
              paidPrice: {
                priceExclTax: unitPriceExclTax ? unitPriceExclTax * quantity : null,
                priceInclTax: unitPriceInclTax ? unitPriceInclTax * quantity : null,
              },
            },
          },
          quantity,
        };
      }),
    };

    if (!itemInExistingPopItems) {
      const payloadItemInFetchedPopItems = pop.fetchedResponse?.products.find(
        (productObject) => productObject.product.lineId === item.itemLineId,
      );
      const canBeAssembled = payloadItemInFetchedPopItems
        ? payloadItemInFetchedPopItems.product.isAssemblyRequired
        : true;

      const popItemWithInitials: PopItem = {
        ...item,
        canBeAssembled,
        checked: false,
        conditionOfGoods: null,
        issue: null,
        popId: purchaseId,
        resolution: 'RETURN_INSTORE',
      };
      updatedPop = {
        ...pop,
        items: [
          ...pop.items,
          popItemWithInitials,
        ],
      };
    }

    return updatedPop;
  });
};

export const filterBlindReturnItemsByItemNo = (
  state: OTCState,
  filterItemNo: string | undefined,
) => {
  if (!filterItemNo) return;

  const updatedBlindReturnItems = state.blindReturn.items.filter(
    (item) => item.itemNo !== filterItemNo,
  );
  state.blindReturn.items = updatedBlindReturnItems;
};

export const removeDots = (text: string) => text.replace(/\./g, '');

type MapFetchedProductToStateItemProps = {
  deliveryReference?: string;
  itemLineId: string;
  product: Product | ProductInfo;
  quantity?: number;
};
export const mapFetchedProductToStateItem = ({
  deliveryReference = '0',
  itemLineId,
  product,
  quantity = 1,
}: MapFetchedProductToStateItemProps) => {
  const productPrice = 'price' in product ? product.price : null;
  const unitPriceExclTax = productPrice?.priceExclTax ?? null;
  const unitPriceInclTax = productPrice?.priceInclTax ?? null;

  return {
    canBeAssembled: product.isAssemblyRequired,
    currencyCode: productPrice?.currencyCode ?? '',
    deliveryReference,
    description: product?.type ?? '', // not received in fetched product
    dimensions: null,
    itemNo: removeDots(product.productNumber),
    itemLineId: 'lineId' in product ? product.lineId : itemLineId,
    itemType: product.itemType ?? 'ART',
    name: product.name ?? 'No name',
    price: {
      total: {
        paidPrice: {
          priceExclTax: unitPriceExclTax ? unitPriceExclTax * quantity : null,
          priceInclTax: unitPriceInclTax ? unitPriceInclTax * quantity : null,
        },
      },
      unit: {
        paidPrice: {
          priceExclTax: unitPriceExclTax,
          priceInclTax: unitPriceInclTax,
        },
      },
    },
    productImage: {
      alt: product.media?.alt ?? null,
      url: product.media?.variants.S2 ?? '',
    },
    quantity,
  };
};
