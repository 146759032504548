import { createAction } from '@reduxjs/toolkit';
import { CustomerReturn } from '../../../models';
import { PopItem } from '../OTCSlice/reducerUtils';

export const sidebarKeys = [
  'history-log',
  'more-menu',
  'otc-article-actions',
  'otc-article-viewer',
  'otc-blind-return-article-actions',
  'otc-condition-of-goods',
  'otc-issue-picker',
  'otc-pop-actions',
  'otc-search',
  'questionnaire',
  'return-method',
  'time-window-calendar',
] as const;

export type SidebarPageKey = typeof sidebarKeys[number];

export const timeWindowSet = createAction<{
  hasCommittedMethod: boolean
  timeWindowHasChangedInViewMode: boolean
}>('timeWindowSet');

export type ArticlesWithIncorrectUnit = {
  name?: string;
  productNumber?: string;
}[] | null;
export const articlesWithIncorrectUnit = createAction<ArticlesWithIncorrectUnit>(
  'articlesWithIncorrectUnit',
);

export type InitialCreateModePayload = {
  accessToken: string;
  customerReturn: CustomerReturn | null;
  isAuthenticated: boolean;
  userId: string | null;
};
export const setInitialCreateMode = createAction<InitialCreateModePayload>(
  'setInitialCreateMode',
);
export const removePoP = createAction('removePoP');

export const showOTCArticleSidebar = createAction<{
  article: PopItem,
  sidebarPage: SidebarPageKey,
}>('showOTCArticleSidebar');

export const removeArticlesPop = createAction('remove-article-pop');
export const removeArticle = createAction('remove-article');
export const removeBlindReturnArticle = createAction('remove-blind-return-article');
