import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';

export default function MissingCustomerInfoBody() {
  const customerReturn = useGetAppState('customerReturn');
  const { t } = useTranslation();

  if (!customerReturn) return null;

  const {
    customer: { billingInfo, deliveryInfo: { email, mobile, phone } },
  } = customerReturn;
  const showPreferredMessage = !email && !mobile && !phone;
  const showBillingMessage = !billingInfo.email;

  return (
    <ul>
      {showPreferredMessage && (
        <li>
          <Text>
            {t('prompt-actions.missing-customer-information.missingPreferredCommunicationOptions')}
          </Text>
        </li>
      )}
      {showBillingMessage && (
        <li className="mt-4">
          <Text>
            {t('prompt-actions.missing-customer-information.missingBillingEmail')}
          </Text>
        </li>
      )}
    </ul>
  );
}
