import { ModalFooter } from '@ingka/modal';
import { SubmitTimeWindowButton, getDateLocale } from 'shared-frontend';
import {
  useGetSelectedReturnMethodDraft,
  useGetSelectedReturnMethodState,
} from '../../../store/features/selectedReturnMethodSlice/selectedReturnMethodSlice';
import { useTimeWindowSet } from '../../../store/features/standAloneActions/actions';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import { useViewRoute } from '../../../hooks/useAppRoutes';

export function TimeWindowPageFooter() {
  const draft = useGetSelectedReturnMethodDraft();
  const subOptionData = draft.selectedDateSubOptionData;
  const setTimeWindow = useTimeWindowSet();
  const { languageCode, countryCode } = useMarketLocale();
  const locale = getDateLocale(languageCode, countryCode);

  const isView = useViewRoute();
  const selected = useGetSelectedReturnMethodState('selectedReturnMethod')?.timeWindow.timeWindowId;
  const original = draft.reschedule?.originalTimeWindow.timeWindowId;

  if (!subOptionData?.timeWindow) {
    return null;
  }

  const noChangesToSave = selected === original
    && selected === subOptionData.timeWindow.timeWindowId;

  return (
    <ModalFooter renderBorder>
      <SubmitTimeWindowButton
        onClick={() => setTimeWindow(isView && !noChangesToSave)}
        selectedDateSubOption={subOptionData.timeWindow}
        locale={locale}
        disable={
          isView && noChangesToSave
        }
      />
    </ModalFooter>
  );
}
