import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { apiSlice } from '../api/apiSlice';
import { PromptState } from './utils';

export const addApiMatchers = (builder: ActionReducerMapBuilder<PromptState>) => {
  builder
    .addMatcher(apiSlice.endpoints.rescheduleReturn.matchPending, (state) => {
      if (state.open) {
        state.loading = true;
      }
    })
    .addMatcher(apiSlice.endpoints.rescheduleReturn.matchRejected, (state) => {
      state.page = 'retry-reschedule';
      state.loading = false;
      state.open = true;
    })
    .addMatcher(apiSlice.endpoints.rescheduleReturn.matchFulfilled, (state) => {
      state.page = null;
      state.loading = false;
      state.open = false;
    })
    .addMatcher(apiSlice.endpoints.cancelReturn.matchPending, (state) => {
      state.loading = true;
    })
    .addMatcher(apiSlice.endpoints.cancelReturn.matchRejected, (state) => {
      state.page = 'retry-cancel';
      state.loading = false;
      state.open = true;
    })
    .addMatcher(apiSlice.endpoints.cancelReturn.matchFulfilled, (state) => {
      state.page = null;
      state.loading = false;
      state.open = false;
    });
};
