import {
  CompletedQuestionnaire,
  InternalQuestionnaireState,
  IsomReturnOption,
  ParsedTimeWindow,
  Questionnaire,
  QuestionValues,
  ReturnMethodsMarketConfig,
  SelectedReturnMethod,
  SelectedTimeWindow,
} from 'shared-frontend';
import i18next from 'i18next';
import { GetQuestionnaireResponses } from '../../../models';

export type SerializedTimeWindow = Omit<ParsedTimeWindow, 'fromDateTime' | 'toDateTime'> & { fromDateTime: string, toDateTime: string };
export interface SelectedReturnMethodState {
  questionnaireCompleted: CompletedQuestionnaire | null;
  questionnaireControl: InternalQuestionnaireState | null;
  selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null;
  draft: {
    questionnaireControl: InternalQuestionnaireState;
    questionnaireValid: boolean;
    selectedDateSubOptionData: {
      timeWindow: SerializedTimeWindow;
    } | null;
    selectedReturnMethod: SelectedReturnMethod<IsomReturnOption> | null;
    reschedule: {
      originalTimeWindow: SelectedTimeWindow,
      successfulRescheduleTimeWindowId?: string,
    } | null
  };
  returnMethodsMarketConfig: ReturnMethodsMarketConfig | null;
}

export function mergeQuestionnaireAnswers(
  questionnaire: Questionnaire,
  completedQuestionnaire: CompletedQuestionnaire['questionnaire'],
): QuestionValues[] {
  const mergedQuestions: (
    QuestionValues | null)[] = questionnaire.questions.map((qResponseQuestion) => {
    const matchAnswerToQuestion = completedQuestionnaire?.questions
      .find((a) => a.reference === qResponseQuestion.reference);

    if (!matchAnswerToQuestion) return null;
    const freeText = matchAnswerToQuestion?.freeText;
    const mergedQ: QuestionValues = {
      reference: qResponseQuestion.reference,
      translatedReference: qResponseQuestion.translatedReference,
      mandatory: qResponseQuestion.mandatory,
      selectedOption: matchAnswerToQuestion.selectedOption,
      answerLabel: i18next.t(`questionnaire-sidebar.${matchAnswerToQuestion.selectedOption.toLocaleLowerCase()}`),
      options: qResponseQuestion.options,
      ...(freeText && {
        freeText,
        isFreeTextValid: true,
      }),
    };
    return mergedQ;
  });

  const removeUnmatchedAnswers = mergedQuestions.filter(
    (q: QuestionValues | null): q is QuestionValues => q !== null,
  );

  return removeUnmatchedAnswers;
}

export function matchQuestionnaireTypeToApiResponse(
  questionnaires: Questionnaire[],
  type: Questionnaire['type'],
): Questionnaire | null {
  return questionnaires.find((q) => q.type === type) || null;
}

export function questionnaireAnswersToCompleted(
  questionnaire: GetQuestionnaireResponses | undefined,
): CompletedQuestionnaire | null {
  if (!questionnaire) return null;

  const { questions, type, translatedType } = questionnaire;
  const completedQuestionnaire: CompletedQuestionnaire['questionnaire'] = {
    type,
    translatedType,
    questions: questions.map((q) => ({
      freeText: q.freeText ?? null,
      reference: q.reference,
      selectedOption: q.selectedOption ?? 'UNDEFINED',
      translatedReference: q.translatedReference,
    })),
  };
  return {
    questionnaire: completedQuestionnaire,
  };
}
