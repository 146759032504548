import Button from '@ingka/button';
import { useTranslation } from 'react-i18next';
import { useClosePrompt, useGetPromptState } from '../../store/features/prompt/promptSlice';
import { useCancelReturn } from '../../hooks/useCancelReturn/useCancelReturn';
import { useRescheduleReturn } from '../../hooks/useRescheduleReturn';

export const CUSTOMER_SERVICE_HREF = 'https://nowit.ingka.com/sp?_ga=2.72221267.1506028405.1646405772-833402824.1620650625';

export function RetryActionFooter() {
  const closePrompt = useClosePrompt();
  const isLoading = useGetPromptState('loading');
  const { rescheduleReturn } = useRescheduleReturn();
  const cancelReturn = useCancelReturn();
  const { t } = useTranslation();

  const promptPage = useGetPromptState('page');
  const getOnClickHandler = () => {
    switch (promptPage) {
      case 'retry-cancel':
        return cancelReturn;
      default:
        return rescheduleReturn;
    }
  };

  return (
    <>
      <Button
        className="whitespace-nowrap !mr-2"
        disabled={isLoading}
        href={CUSTOMER_SERVICE_HREF}
        newWindow
        onClick={closePrompt}
        text={t('prompt-actions.retry-footer-actions.secondary')}
        type="secondary"
      />
      <Button
        className="whitespace-nowrap"
        loading={isLoading}
        text={t('prompt-actions.retry-footer-actions.primary')}
        type="primary"
        onClick={getOnClickHandler()}
      />
    </>
  );
}

// This is needed because Skapa validates the displayname to be like below
RetryActionFooter.displayName = 'Button';
