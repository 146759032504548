import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { getEnabledFeatures } from 'shared-frontend';
import { apiSlice } from '../api/apiSlice';
import { GetCustomerReturnResponse, RescheduleSuccess } from '../../../models';
import { AppStateInterface, isViewReturn, mapGetReturnResponseToCustomerReturn } from './utils';
import { CancelReturnSuccessResponse } from '../api/cancelReturn/cancelReturn';
import { MarketConfig } from '../api/marketConfig/marketConfig';
import { sortTimeWindows } from '../../../utils/TimeWindows/timeWindowsUtils';

export const addApiMatchers = (builder: ActionReducerMapBuilder<AppStateInterface>) => {
  builder
    .addMatcher(apiSlice.endpoints.rescheduleReturn.matchFulfilled, (
      state: AppStateInterface,
      action: PayloadAction<RescheduleSuccess>,
    ) => {
      if (!isViewReturn(state.customerReturn)) { return; }
      state.showSaveCustomerReturnButton = false;
      state.statusViewModel = action.payload.statusViewModel;
      state.customerReturn.actions = action.payload.actions;
    })
    .addMatcher(apiSlice.endpoints.submitReturn.matchFulfilled, (state, action) => {
      state.returnReference = action.payload.returnReference;
      state.statusViewModel = action.payload.statusViewModel;
    })
    .addMatcher(apiSlice.endpoints.getCustomerReturn.matchFulfilled, (
      state: AppStateInterface,
      action: PayloadAction<GetCustomerReturnResponse>,
    ) => {
      if (!state.accessToken) return;
      state.returnReference = action.payload.returnReference;
      state.returnId = action.payload.returnId;
      state.statusViewModel = action.payload.statusViewModel;
      state.preferredCommunicationMethod = action.payload.customer.preferredCommunicationMethod;
      state.customerReturn = mapGetReturnResponseToCustomerReturn(action.payload);
      state.selfServiceViewURL = action.payload.selfServiceViewURL ?? null;
    })
    .addMatcher(apiSlice.endpoints.cancelReturn.matchFulfilled, (
      state: AppStateInterface,
      action: PayloadAction<CancelReturnSuccessResponse>,
    ) => {
      if (!isViewReturn(state.customerReturn)) { return; }
      const updatedVieWModel = action.payload.statusViewModel;
      const updatedActions = action.payload.actions;
      state.showSaveCustomerReturnButton = false;
      state.statusViewModel = updatedVieWModel;
      state.customerReturn.actions = updatedActions;
    })
    .addMatcher(apiSlice.endpoints.marketConfig.matchFulfilled, (
      state: AppStateInterface,
      action: PayloadAction<MarketConfig>,
    ) => {
      state.features = getEnabledFeatures(action.payload.features);
    })
    .addMatcher(apiSlice.endpoints.getAvailableTimeWindows.matchFulfilled, (
      state: AppStateInterface,
      action: PayloadAction<any>,
    ) => {
      if (!isViewReturn(state.customerReturn)) { return; }
      const { returnOption } = state.customerReturn.returnAgreement.returnSettlement;
      const {
        timeWindow: { id, toDateTime, fromDateTime },
        metadata: { timeWindow: { timezone, resourcePoolId, tspData } },
      } = returnOption;
      const currentlySelectedTimeWindow = {
        [id]: {
          toDateTime,
          fromDateTime,
          resourcePoolId,
          timezone,
          tspData,
        },
      };
      const { availableTimeWindows: apiTimeWindows, returnOptionsId } = action.payload;
      state.rescheduleAvailableTimeWindows = {
        returnOptionsId,
        proposedTimeWindowId: id,
        availableTimeWindows: sortTimeWindows({
          ...apiTimeWindows,
          ...currentlySelectedTimeWindow,
        }),
      };
    });
};
