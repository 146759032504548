import Button from '@ingka/button';
import Collapsible from '@ingka/collapsible';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down';
import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

type CollapsibleState = {
  isExpanded: boolean;
  toggleExpand: () => void;
};
const CollapsibleContext = createContext<CollapsibleState>({
  isExpanded: true,
  toggleExpand: () => { },
});

export function useCollapsible() {
  return useContext(CollapsibleContext);
}

export function CollapsibleProvider({ children }: { children: ReactNode }) {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => setIsExpanded((prev) => !prev);

  const collapsibleState = useMemo(() => ({
    isExpanded,
    toggleExpand,
  }), [isExpanded]);

  return (
    <CollapsibleContext.Provider value={collapsibleState}>
      {children}
    </CollapsibleContext.Provider>
  );
}

export function CollapsibleSection({
  children,
  id,
}: { children: ReactNode, id: string }) {
  const { isExpanded } = useCollapsible();

  return (
    <Collapsible id={id} expanded={isExpanded} data-testid="collapsible">
      {children}
    </Collapsible>
  );
}

export function CollapsibleButton({ disabled }: { disabled: boolean }) {
  const { isExpanded, toggleExpand } = useCollapsible();
  const { t } = useTranslation();

  return (
    <Button
      className={
        `items-center ml-auto transition-all duration-200 ease-custom-ease transform ${isExpanded ? 'rotate-180' : 'rotate-0'}`
      }
      disabled={disabled}
      onClick={toggleExpand}
      ssrIcon={chevronDown}
      iconOnly
      type="tertiary"
      size="small"
      aria-expanded={isExpanded}
      text={t('receipt-card.toggleBtn')}
    />
  );
}
