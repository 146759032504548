import { Statusbar as StatusbarComponent, StatusbarStep, stepViewModelMapper } from 'shared-frontend';
import { useTranslation } from 'react-i18next';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { FailedStepActions } from './FailedStepActions';
import { CreateFailedMessage } from './CreateFailedMessage';

function Statusbar({ skipLabel }: { skipLabel?: string }) {
  const statusViewModel = useGetAppState('statusViewModel');
  const { t } = useTranslation();

  if (!statusViewModel) return null;

  const statusbarSteps: StatusbarStep[] = statusViewModel.map(stepViewModelMapper);

  const additionalComponent = () => {
    const showCreateFailedMessage = statusViewModel.some(({ step }) => step === 'CREATE_FAILED');
    if (showCreateFailedMessage) {
      return <CreateFailedMessage />;
    }

    const activeStepFailed = statusbarSteps.some((step) => step.active && step.hasError);
    if (activeStepFailed) {
      return <FailedStepActions />;
    }

    return null;
  };

  return (
    <>
      <StatusbarComponent
        steps={statusbarSteps}
        skipLabel={skipLabel}
        ariaLabelLeftBtn={t('statusViewModel.steps.statusbar-carousel.ariaPrevious')}
        ariaLabelRightBtn={t('statusViewModel.steps.statusbar-carousel.ariaNext')}
      />
      {additionalComponent()}
    </>
  );
}

export default Statusbar;
