import InlineMessage from '@ingka/inline-message';
import RadioButtonsGroup from '@ingka/radio-button-group';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import { useTranslation } from 'react-i18next';
import {
  useGetAppState,
  useSetPreferredCommunicationMethod,
} from '../../../store/features/appStateSlice/appStateSlice';
import { CustomerReturn } from '../../../models';
import { PreferredCommunicationMethod } from '../../../models/CustomerReturnRequest';

type CommunicationOptions = {
  label: string;
  value: PreferredCommunicationMethod;
}[];

function PreferredCommunicationOptions() {
  const { t } = useTranslation();
  const setPreferredCommunicationMethod = useSetPreferredCommunicationMethod();
  const preferredCommunicationMethod = useGetAppState('preferredCommunicationMethod');
  const returnReference = useGetAppState('returnReference');
  const { customer } = useGetAppState('customerReturn') as CustomerReturn;
  const { deliveryInfo: { email, mobile, phone } } = customer;

  const cellularOption = mobile || phone;
  const communicationOptions: CommunicationOptions = [];
  if (email) {
    communicationOptions.push({ label: email, value: 'EMAIL' });
  }
  if (cellularOption) {
    communicationOptions.push({ label: cellularOption, value: 'SMS' });
  }

  if (communicationOptions.length === 0) {
    return (
      <InlineMessage
        body={t('prompt-actions.missing-customer-information.missingPreferredCommunicationOptions')}
        className="break-normal"
        ssrIcon={informationCircle}
        subtle
        variant="negative"
      />
    );
  }

  const radioOptions = communicationOptions.map(({ label, value }) => ({
    checked: preferredCommunicationMethod === value,
    id: value,
    label,
    name: 'preferredCommunicationOptionsRadios',
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedOption = e.target.value as PreferredCommunicationMethod;
      setPreferredCommunicationMethod(selectedOption);
    },
    value,
  }));

  const disabled = communicationOptions.length === 1 || Boolean(returnReference);
  return (
    <RadioButtonsGroup
      className="[&_.radio:not(:last-child)]:mb-2 [&_.radio_input[type=radio]:disabled_~_.radio\_\_label_label]:!text-lightest"
      disabled={disabled}
      list={radioOptions}
    />
  );
}

export default PreferredCommunicationOptions;
