import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn, EndpointBuilder, FetchArgs, FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { getQueryParams } from 'shared-frontend';
import { isAfter } from 'date-fns';
import config from '../../../config';
import { consumer } from './apiConfig';
import { returnMethodsBuilder } from './returnMethods/returnMethod';
import { questionnaireBuilder } from './questionnaire/questionnaire';
import { marketConfigBuilder } from './marketConfig/marketConfig';
import { submitReturnBuilder } from './submitReturn/submitReturn';
import { refundCalcBuilder } from './refundCalc/refundCalc';
import { getCustomerReturnBuilder } from './getCustomerReturn/getCustomerReturn';
import { cancelReturnBuilder } from './cancelReturn/cancelReturn';
import { rescheduleReturnBuilder } from './rescheduleReturn/rescheduleReturn';
import { getAvailableTimeWindowsBuilder } from './getAvailableTimeWindows/getAvailableTimeWindows';
import { getProductInfoBuilder } from './productInfo/getProductInfo';
import type { RootState } from '../../app/store';
import { getCountryAndLanguageFromUrl } from '../../../i18n';
import { getHistoryLogsBuilder } from './historyLog/historyLog';
import { getReturnAuthorizationProductsBuilder } from './returnAuthorization/products';
import { refreshAuth } from '../standAloneActions/saljaUser';

const endpointsWithAuth = [
  'cancelReturn',
  'getCustomerReturn',
  'getHistoryLogs',
  'rescheduleReturn',
  'submitReturn',
  'getReturnAuthorizationProducts',
];

const endpointsWithAcceptLanguage = [
  'cancelReturn',
  'getCustomerReturn',
  'getHistoryLogs',
  'submitReturn',
];

// we wrap RTK fetchBaseQuery in order to fetch config and dynamically set the path
const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  WebApi,
  extraOptions,
) => {
  await config();
  const baseUrl = window.config.VITE_RETURNS_URL;
  const rawBaseQuery = await retry(fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState, endpoint }) => {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      headers.set('x-consumer', consumer);

      if ('beTestMode' in getQueryParams()) {
        headers.set('test-mode', 'true');
      }

      if ('dryRun' in getQueryParams() && endpoint === 'submitReturn') {
        headers.set('dry-run', 'true');
      }

      if (endpointsWithAcceptLanguage.includes(endpoint)) {
        const [country, lang] = getCountryAndLanguageFromUrl();
        headers.set('Accept-Language', `${lang.toLowerCase()}-${country.toUpperCase()}`);
      }

      if (endpointsWithAuth.includes(endpoint)) {
        const storeState = getState() as RootState;
        headers.set('Authorization', `Bearer ${storeState.appState.accessToken}`);
      }

      return headers;
    },
  }), {
    maxRetries: process.env.NODE_ENV === 'test' ? 0 : 1,
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};
// should always be the last wrapper for dynamicBaseQuery.
// It relies on fetchBaseQuery auto retry so if that is removed, this will
// need to call dynamicBaseQuery again after refreshing the token
const dynamicBaseQueryWithReauth: typeof dynamicBaseQuery = async (args, api, extraOptions) => {
  const result = await dynamicBaseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    const storeState = api.getState() as RootState;
    const { expiresOn } = storeState.appState.coworkerAuth;
    if (!expiresOn) {
      return result;
    }
    if (isAfter(new Date(), new Date(expiresOn))) {
      await api.dispatch(refreshAuth());
    }
  }
  return result;
};

export type AppEndPointBuilder = EndpointBuilder<BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, never, 'api'>;

export const apiSlice = createApi({
  baseQuery: dynamicBaseQueryWithReauth,
  keepUnusedDataFor: 9999,
  reducerPath: 'api',
  endpoints: (builder) => ({
    availableReturnMethods: returnMethodsBuilder(builder),
    cancelReturn: cancelReturnBuilder(builder),
    getAvailableTimeWindows: getAvailableTimeWindowsBuilder(builder),
    getCustomerReturn: getCustomerReturnBuilder(builder),
    getHistoryLogs: getHistoryLogsBuilder(builder),
    getProductInfo: getProductInfoBuilder(builder),
    getReturnAuthorizationProducts: getReturnAuthorizationProductsBuilder(builder),
    marketConfig: marketConfigBuilder(builder),
    questionnaire: questionnaireBuilder(builder),
    refundCalc: refundCalcBuilder(builder),
    rescheduleReturn: rescheduleReturnBuilder(builder),
    submitReturn: submitReturnBuilder(builder),
  }),
});

export const {
  useAvailableReturnMethodsQuery,
  useCancelReturnMutation,
  useGetAvailableTimeWindowsQuery,
  useGetCustomerReturnQuery,
  useGetHistoryLogsQuery,
  useGetProductInfoQuery,
  useGetReturnAuthorizationProductsQuery,
  useLazyGetProductInfoQuery,
  useLazyGetReturnAuthorizationProductsQuery,
  useMarketConfigQuery,
  useQuestionnaireQuery,
  useRefundCalcQuery,
  useRescheduleReturnMutation,
  useSubmitReturnMutation,
} = apiSlice;
