import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import {
  articlesWithIncorrectUnit,
  ArticlesWithIncorrectUnit,
  InitialCreateModePayload,
  removeArticle,
  removeBlindReturnArticle,
  removePoP,
  removeArticlesPop,
  setInitialCreateMode,
} from '../standAloneActions/extraReducersActions';
import { addApiMatchers } from './apiMatchers';
import { PromptPageKey, PromptState } from './utils';

export const initialState: PromptState = {
  loading: false,
  open: false,
  page: null,
};

export const promptSlice = createSlice({
  name: 'prompt',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    closePrompt: (state, { payload }: PayloadAction<PromptPageKey>) => {
      // we want the payload so other slices can know when we are closing the reschedule retry
      state.loading = false;
      state.open = false;
      state.page = null;
    },
    openPromptToPage: (state, { payload }: PayloadAction<PromptPageKey>) => {
      state.loading = false;
      state.open = true;
      state.page = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setInitialCreateMode, (
        state: PromptState,
        action: PayloadAction<InitialCreateModePayload>,
      ) => {
        if (!action.payload.customerReturn) return;
        const {
          customerReturn: {
            customer: { billingInfo, deliveryInfo: { email, mobile, phone } },
          },
        } = action.payload;
        if ((!email && !mobile && !phone) || !billingInfo.email) {
          state.page = 'missing-customer-information';
          state.loading = false;
          state.open = true;
        }
      })
      .addCase(articlesWithIncorrectUnit, (
        state: PromptState,
        action: PayloadAction<ArticlesWithIncorrectUnit>,
      ) => {
        if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.loading = false;
          state.open = true;
          state.page = 'articles-with-incorrect-unit';
        }
      })
      .addMatcher(isAnyOf(
        removePoP,
        removeArticlesPop,
        removeArticle,
        removeBlindReturnArticle,
      ), (state) => {
        state.loading = false;
        state.open = false;
        state.page = null;
      });

    addApiMatchers(builder);
  },
});

export const useGetPromptState = <Key extends keyof PromptState>(key: Key) => useLamnaSelector(
  (state) => state.prompt[key],
);

export const useClosePrompt = () => {
  const page = useGetPromptState('page');
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(promptSlice.actions.closePrompt(page));
  };
};

export const useGoToPromptPage = (page: PromptPageKey) => {
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(promptSlice.actions.openPromptToPage(page));
  };
};
