import { useTranslation } from 'react-i18next';
import { ErrorFallback } from 'shared-frontend';
import { useGetAppState } from './store/features/appStateSlice/appStateSlice';
import { useMarketConfigFetch } from './useMarketConfigFetch';
import { isValidatedMarketConfig } from './store/features/api/marketConfig/marketConfig';
import { useBaseRoute } from './hooks/useAppRoutes';

interface App {
  children: JSX.Element | JSX.Element[] | null;
}

export function AuthenticatedApp({ children }: App) {
  const { t } = useTranslation();
  const isAuthenticated = useGetAppState('isAuthenticated');
  const isBaseRoute = useBaseRoute();

  return isAuthenticated
    ? children
    : (isBaseRoute && (
      <ErrorFallback
        title={t('error.fallback-title')}
        body={t('error.authentication-failed')}
        testId="authentication-failed"
      />
    ));
}

export function MarketConfigFetch({ children }: App) {
  const {
    data: marketConfig,
  } = useMarketConfigFetch();

  return isValidatedMarketConfig(marketConfig)
    ? children
    : null;
}
