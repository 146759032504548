import { PriceItem, StatusIcon } from 'shared-frontend';
import Text from '@ingka/text';
import { useTranslation } from 'react-i18next';
import { useMarketLocale } from '../../../hooks/useMarketLocale';
import ItemConditionOfGoods from '../ItemConditionOfGoods/ItemConditionOfGoods';
import ItemDetails from '../ItemDetails/ItemDetails';
import ItemInput from '../ItemInput/ItemInput';
import ReceivingInfo from '../ItemConditionOfGoods/ReceivingInfo';
import {
  getArticleItemType,
  getPrices,
} from '../ArticlesReasonCardUtils';
import { ArticleListItemProps } from '../types';

function ArticleListItem({ article }: { article: ArticleListItemProps }) {
  const { t } = useTranslation();
  const translations = {
    unexpected: t('articles-reason-card.quantityDeviation.unexpected'),
    priceUnavailable: t('articles-reason-card.quantityDeviation.priceUnavailable'),
  };
  const { locale } = useMarketLocale();
  const type = getArticleItemType(article);

  const {
    priceExclTax: totalPriceExclTax,
    priceInclTax: totalPriceInclTax,
    currencyCode: totalPriceCurrency,
  } = getPrices(article, 'totalPrice');

  const totalAmount = (totalPriceExclTax || totalPriceInclTax);
  const unexpectedItem = type === 'UNEXPECTED_ITEM';

  return (
    <li
      className={`grid grid-cols-1 m:grid-cols-2 xl:grid-cols-4 gap-2 m:gap-4 l:gap-6 py-4 px-2 m:px-4
        l:pr-10 l:pl-6 border-t-[1px] border-t-neutral-grey-200 first:-mt-2`}
      data-testid="article-list-item"
    >
      <div className="mb-2 m:mb-0">
        <ItemDetails article={article} withNoPriceWarning={unexpectedItem} />
      </div>
      <div className="m:order-3 xl:order-2">
        <ItemInput article={article} />
        {unexpectedItem && (
          <StatusIcon
            icon="error"
            label={translations.unexpected}
            testId="unexpected-warning"
          />
        )}
      </div>
      <div className="m:order-4 xl:order-3">
        <div className={`flex flex-col gap-0 ${article.unit === 'Pieces' ? 'm:gap-2' : 'm:gap-3'}`}>
          <ItemConditionOfGoods
            conditionOfGoods={article.conditionOfGoods}
          />
          <ReceivingInfo article={article} />
        </div>
      </div>
      <div className="m:order-2 xl:order-4 mt-2 m:mt-0">
        {(!totalPriceCurrency || unexpectedItem) ? (
          <Text
            headingSize="m"
            className="flex xl:justify-end !leading-none"
          >
            {translations.priceUnavailable}
          </Text>
        ) : (
          <PriceItem
            amount={totalAmount ?? 0}
            className="flex xl:justify-end"
            currencyCode={totalPriceCurrency}
            locale={locale}
          />
        )}
      </div>
    </li>
  );
}

export default ArticleListItem;
