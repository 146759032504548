import Button from '@ingka/button';
import { ModalBody } from '@ingka/modal';
import { IssueCategory } from '@resolutions/condition-of-goods';
import { IssueType } from '../../../store/features/OTCSlice/reducerUtils';
import { useSetArticleIssue } from '../../../store/features/OTCSlice/OTCSlice';

export function OTCIssuePickerBody() {
  const setArticleIssue = useSetArticleIssue();

  const issue = {
    actor: 'IKEA',
    id: '0.0.0',
    category: IssueCategory.GENERAL_COMPLAINT,
    mainReasonCode: '05',
    subReasonCode: '03',
    title: 'Article never worked',
  };

  return (
    <ModalBody>
      <Button
        type="primary"
        disabled={false}
        text="Set an issue"
        onClick={() => setArticleIssue(issue)}
        data-testid="ISSUE_ADD"
      />
      <Button
        type="secondary"
        disabled={false}
        text="Reset issue"
        onClick={() => setArticleIssue(null as unknown as IssueType)}
        data-testid="ISSUE_RESET"
      />
    </ModalBody>
  );
}
