import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getArticleItemType } from './ArticlesReasonCardUtils';
import { ArticleListItemProps } from './types';
import { useSetArticlesWithIncorrectUnit } from '../../store/features/standAloneActions/actions';
import { ArticlesWithIncorrectUnit } from '../../store/features/standAloneActions/extraReducersActions';
import { useGetProductInfoQuery } from '../../store/features/api/apiSlice';
import { useGetAppState } from '../../store/features/appStateSlice/appStateSlice';
import { useMarketLocale } from '../../hooks/useMarketLocale';
import { ReturnArtItem } from '../../models/CustomerReturn';

const removeDotsFromProductNo = (productNumber: string) => productNumber.replace(/\./g, '');

export type HookReturn = {
  enrichedProducts: ArticleListItemProps[] | null;
  isError: boolean;
  isLoading: boolean;
};

export const useProductInformation = (): HookReturn => {
  const { t } = useTranslation();
  const setArticlesWithIncorrectUnit = useSetArticlesWithIncorrectUnit();
  const customerReturn = useGetAppState('customerReturn')!;
  const { countryCode, languageCode } = useMarketLocale();
  const [hookState, setHookState] = useState<HookReturn>({
    enrichedProducts: null,
    isError: false,
    isLoading: true,
  });

  const { items: customerReturnItems } = customerReturn;
  const productNumbers = [...new Set(
    customerReturnItems.map((article) => article.itemNo),
  )].join(',');

  const { data, isError } = useGetProductInfoQuery({
    countryCode, languageCode, productNumbers,
  }, {
    skip: productNumbers.length === 0,
  });

  useEffect(() => {
    if (isError) {
      setArticlesWithIncorrectUnit([]);
      setHookState({
        enrichedProducts: customerReturn.items,
        isError,
        isLoading: false,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (!data) return;

    const articlesWithIncorrectUnit: ArticlesWithIncorrectUnit = [];

    const enrichedProducts: ArticleListItemProps[] = customerReturn.items.map((article) => {
      const matchingProduct = data.find((fetchedProduct) => removeDotsFromProductNo(
        fetchedProduct.productNumber,
      ) === article.itemNo);

      const {
        media,
        name: productName,
        productNumber,
        type: productDescription,
        unitCode,
      } = matchingProduct ?? {};

      const articleType = getArticleItemType(article);
      const name = articleType === 'SERVICE_ITEM'
        ? t('articles-reason-card.deliveryItemType')
        : productName;

      const articleHasUnitProperty = articleType === 'ARTICLE_ITEM' || articleType === 'MISSING_ARTICLE_ITEM';
      const articleMetersDoesNotMatchFetchedUnit = unitCode === 'METER' && (article as ReturnArtItem).unit !== 'Meters';
      const articlePiecesDoesNotMatchFetchedUnit = unitCode === 'PIECE' && (article as ReturnArtItem).unit !== 'Pieces';
      if (articleHasUnitProperty && (
        articleMetersDoesNotMatchFetchedUnit || articlePiecesDoesNotMatchFetchedUnit)) {
        articlesWithIncorrectUnit.push({ name, productNumber });
      }

      return {
        ...article,
        media,
        name,
        productNumber,
        ...(productDescription !== 'unknown' && { productDescription }),
      };
    });

    setArticlesWithIncorrectUnit(articlesWithIncorrectUnit);
    setHookState({
      enrichedProducts,
      isError: false,
      isLoading: false,
    });
  }, [data]);

  return hookState;
};
