import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useLamnaSelector, useLamnaDispatch } from '../../app/hooks';
import {
  questionnaireSaved,
  returnMethodSaved,
  timeWindowSet,
  setActionsSidebarPop,
} from '../standAloneActions/actions';
import { SidebarPageKey, showOTCArticleSidebar } from '../standAloneActions/extraReducersActions';
import { useGetAppState } from '../appStateSlice/appStateSlice';
import { useViewRoute } from '../../../hooks/useAppRoutes';
import { TimeWindow } from '../../../models/CustomerReturnRequest';
import { promptSlice } from '../prompt/promptSlice';
import { apiSlice } from '../api/apiSlice';
import { PromptPageKey } from '../prompt/utils';

export type SidebarState = {
  open: boolean;
  page: SidebarPageKey;
};
export type OpenPagePayload = {
  originalTimeWindow?: TimeWindow | null
  page: SidebarPageKey,
};

export const initialState: SidebarState = {
  open: false,
  page: 'return-method',
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebarPage: (state, { payload }: PayloadAction<SidebarPageKey>) => {
      state.page = payload;
    },
    openSidebarToPage: (state, { payload }: PayloadAction<OpenPagePayload>) => {
      state.page = payload.page;
      state.open = true;
    },
    // we need the payload so other reducers can know what page is being closed,
    // and we want it to be typed so we declare it here
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    closeSidebar: (state, { payload }: PayloadAction<SidebarPageKey>) => {
      state.open = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(returnMethodSaved, (state, action) => {
        if (action.payload?.hasQuestionnaire) {
          state.page = 'questionnaire';
        } else {
          state.open = false;
        }
      })
      .addCase(timeWindowSet, (state, { payload }) => {
        if (payload?.hasCommittedMethod) {
          state.open = false;
        } else {
          state.page = 'return-method';
        }
      })
      .addCase(questionnaireSaved, (state) => {
        state.open = false;
      })
      .addCase(showOTCArticleSidebar, (state, { payload }) => {
        state.page = payload.sidebarPage;
        state.open = true;
      })
      .addCase(setActionsSidebarPop, (state, { payload }) => {
        if (payload) {
          state.page = 'otc-pop-actions';
        }
        state.open = !!payload;
      })
      .addCase(promptSlice.actions.openPromptToPage, (state, { payload }) => {
        const closeSidebarPromptActions: PromptPageKey[] = [
          'otc-remove-pop',
          'otc-remove-selected-articles-pop',
          'otc-remove-article',
          'otc-remove-blind-return-article',
        ];
        if (closeSidebarPromptActions.includes(payload)) {
          state.open = false;
        }
      })
      .addMatcher(
        apiSlice.endpoints.getReturnAuthorizationProducts.matchPending,
        (state) => {
          state.open = false;
        },
      );
  },
});

export const useGetSidebarState = <Key extends keyof SidebarState>(key: Key) => useLamnaSelector(
  (state) => state.sidebar[key],
);

export const useCloseSidebar = () => {
  const dispatch = useLamnaDispatch();
  const page = useGetSidebarState('page');
  return () => {
    dispatch(sidebarSlice.actions.closeSidebar(page));
  };
};

export const useGoToSidebarPage = (page: SidebarPageKey) => {
  const rescheduleTimeWindows = useGetAppState('rescheduleAvailableTimeWindows');
  const proposed = rescheduleTimeWindows?.proposedTimeWindowId;
  const isView = useViewRoute();
  let originalTimeWindow: TimeWindow | null = null;
  if (isView && proposed) {
    originalTimeWindow = {
      ...rescheduleTimeWindows.availableTimeWindows[proposed],
      id: proposed,
    };
  }
  const dispatch = useLamnaDispatch();
  return () => {
    dispatch(sidebarSlice.actions.openSidebarToPage({ page, originalTimeWindow }));
  };
};
